import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ColumnModel, CommandModel, EditSettings, EditSettingsModel, GridComponent, QueryCellInfoEventArgs, RowDataBoundEventArgs } from "@syncfusion/ej2-angular-grids";
import { cssClass } from "@syncfusion/ej2-angular-lists";
import { L10n, setCulture } from "@syncfusion/ej2-base";
import { AdministrationService } from "src/app/shared/services/admin.service";

const localede = {
    "de": {
        grid: {
            Add: 'Add threat'
        }
    }
};

setCulture("de");
L10n.load(localede);

@Component({
    selector: 'admin-threats',
    styleUrls: ['threats.css'],
    templateUrl: 'threats.html',
    encapsulation: ViewEncapsulation.None
})
export class ThreatsComponent implements OnInit {
    constructor(private adminService: AdministrationService) { }
    private data: any;
    public assetsColumns: ColumnModel[];
    public effectColumns: ColumnModel[];
    public customAttributes;
    public threatLevels;
    public categories;
    public selectedCategories: string[] = [];
    public selectedThreatLevels: string[] = [];
    public selectedThreatLevel: string = '';
    public ddlfields: Object = { text: 'levelName', value: 'levelId' };
    public catfields: Object = { text: 'categoryName', value: 'categoryId' };
    public riskfields: Object = { text: 'riskTypeName', value: 'riskTypeId' };
    public actorfields: Object = { text: 'actorName', value: 'actorId' };
    public intentionfields: Object = { text: 'intentionName', value: 'intentionId' };
    public toolbar: string[];
    public editSettings: EditSettingsModel;
    public adminThreats: any[] = [];
    public actors: any[] = [];
    public intentions: any[] = [];
    public riskTypes: any[] = [];

    @ViewChild('gridAdminThreats')
    public grid?: GridComponent;
    public commands?: CommandModel[]
    // = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } }];

    ngOnInit(): void {
        this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', buttonOption: { cssClass: 'e-flat colorBronze', iconCss: 'e-delete e-icons' } }];
        this.getAdminThreats();
        this.getCategories();
        this.getRiskTypes();
        this.getActors();
        this.getThreatLevels();
        this.getIntentions();
        this.customAttributes = { class: 'orientationcss' };

        this.assetsColumns = [
            { field: 'internalOrganisation', headerText: 'Internal organisation', width: 100, customAttributes: this.customAttributes },
            { field: 'internalEmployees', width: 100, headerText: 'Internal employees', customAttributes: this.customAttributes },
            { field: 'internallyHostedApplications', width: 100, headerText: 'Internally hosted applications', customAttributes: this.customAttributes },
            { field: 'internallyHostedITInfrastructure', width: 100, headerText: 'Internally hosted IT infrastructure', customAttributes: this.customAttributes },
            { field: 'iCTServiceProvidersOrganisation', width: 100, headerText: 'ICT service providers organisation', customAttributes: this.customAttributes },
            { field: 'iCTServiceProvidersEmployees', width: 100, headerText: 'ICT service providers employees', customAttributes: this.customAttributes },
            { field: 'externallyHostedApplications', width: 100, headerText: 'Externally hosted applications', customAttributes: this.customAttributes },
            { field: 'externallyHostedITInfrastructure', width: 100, headerText: 'Externally hosted IT infrastructure', customAttributes: this.customAttributes }
        ]

        this.effectColumns = [
            { field: 'confidentiality', width: 100, headerText: 'Confidentiality', customAttributes: this.customAttributes, template: '<div *ngIf="confidentiality===1">X</div>' },
            { field: 'integrity', width: 100, headerText: 'Integrity', customAttributes: this.customAttributes, template: '<div *ngIf="integrity===1">X' },
            { field: 'availability', width: 100, headerText: 'Availability', customAttributes: this.customAttributes, template: '<div *ngIf="availability===1">X</div>' }
        ]

        this.toolbar = ['Add'];
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Bottom' };
    }

    created() {
        let toolbar = ((this.grid as GridComponent).element as HTMLElement).querySelector('.e-toolbar');
        (this.grid as GridComponent).element.appendChild(toolbar as HTMLElement);
    }

    public rowDataBound(args: RowDataBoundEventArgs) {
            args.rowHeight = 40;
    }

    actionBegin(args: any): void {
        if (args.requestType === 'save' && (args.action === 'add' || args.action === 'edit')) {
            var categoryId = args.data.category.categoryId;
            var relatedCategory = this.categories.filter(x => x.categoryId === categoryId)[0];
            args.data.category = relatedCategory;
            this.adminService.AddUpdateThreats(args.data).subscribe(resp => {
                args.data.threatId = resp;
            })
        }

        if (args.requestType === 'delete') {
            this.adminService.deleteAdminThreat(args.data[0].threatId).subscribe();
        }
    }

    public setHeaderHeight() {
        // const textWidth: number = (document.querySelector(".orientationcss > div") as HTMLElement).scrollWidth;
        // const headerCell: NodeList = document.querySelectorAll(".e-headercell");
        // for (let i: number = 0; i < headerCell.length; i++) {
        //     ((headerCell as any).item(i)).style.height = 50 + 'px';
        //}
    }
    previousCategory: string = '';
    categoryStartIndex: number = 0;

    previousColumn2: string = '';
    column2StartIndex: number = 0;

    queryCellInfo(args: QueryCellInfoEventArgs) {
        const data = args.data as any;
        const index = parseInt((args.cell as HTMLElement).getAttribute('index')!, 10);

        if (args.column.field === 'category.categoryName') {
            if (this.previousCategory === '') {
                this.previousCategory = data['category.categoryName'];
                this.categoryStartIndex = index;
            } else if (this.previousCategory !== data['category.categoryName']) {
                this.mergeCells('category.categoryName', this.categoryStartIndex, index);
                this.previousCategory = data['category.categoryName'];
                this.categoryStartIndex = index;
            }
        }

        if (args.column.field === 'threatLevel.levelName') {
            if (this.previousColumn2 === '') {
                this.previousColumn2 = data['threatLevel.levelName'];
                this.column2StartIndex = index;
            } else if (this.previousColumn2 !== data['threatLevel.levelName']) {
                this.mergeCells('threatLevel.levelName', this.column2StartIndex, index);
                this.previousColumn2 = data['threatLevel.levelName'];
                this.column2StartIndex = index;
            }
        }
        const dataSource = this.grid.dataSource as any[];
        if (index === dataSource.length - 1) {
            this.mergeCells('category.categoryName', this.categoryStartIndex, index + 1);
            this.mergeCells('threatLevel.levelName', this.column2StartIndex, index + 1);
        }
    }

    mergeCells(field: string, startIndex: number, endIndex: number) {
        const grid = this.grid;
        const cells = grid.getRows().slice(startIndex, endIndex).map(row =>
            row.querySelector(`[aria-colindex="${grid.getColumnByField(field).index + 1}"]`) as HTMLElement
        );

        if (cells.length > 0) {
            cells[0].style.verticalAlign = 'middle';
            (cells[0] as HTMLTableCellElement).rowSpan = cells.length;

            for (let i = 1; i < cells.length; i++) {
                cells[i].style.display = 'none';
            }
        }
    }

    filterCategory(category: string, event: any): void {
        if (event.checked) {
            this.selectedCategories.push(category);
        } else {
            const index = this.selectedCategories.indexOf(category);
            if (index > -1) {
                this.selectedCategories.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    filterThreatLevel(event: any): void {
        let tl = event.itemData.name;
        if (event.name === "tagging") {
            this.selectedThreatLevels.push(tl);
        }
        if (event.name === "removing") {
            const index = this.selectedThreatLevels.indexOf(tl);
            if (index > -1) {
                this.selectedThreatLevels.splice(index, 1);
            }
        }
        this.applyFilters();
    }

    // Assuming this.selectedCategories and this.selectedThreatLevels are arrays of selected values.
    applyFilters() {
        const selectedCategories = this.selectedCategories;
        const selectedThreatLevels = this.selectedThreatLevels.map(level => level);

        const filteredData = this.data.filter(item => {
            const categoryMatch = selectedCategories.length === 0 || selectedCategories.includes(item.Category);
            const threatLevelMatch = selectedThreatLevels.length === 0 || selectedThreatLevels.includes(item.Column2);
            return categoryMatch && threatLevelMatch;
        });

        this.grid.dataSource = filteredData;
        this.grid.refresh();
    }


    getAdminThreats() {
        this.adminService.getAdminThreats().subscribe(item => {
            this.adminThreats = item;
        },
            error => {
                console.error('Error fetching threats:', error);
            })
    }

    getCategories() {
        this.adminService.getCategories().subscribe(item => {
            this.categories = item;
        },
            error => {
                console.error('Error fetching categories:', error);
            })
    }


    getRiskTypes() {
        this.adminService.getRiskTypes().subscribe(item => {
            this.riskTypes = item;
        },
            error => {
                console.error('Error fetching risk types:', error);
            })
    }

    getActors() {
        this.adminService.getActors().subscribe(item => {
            this.actors = item;
        },
            error => {
                console.error('Error fetching actors:', error);
            })
    }

    getThreatLevels() {
        this.adminService.getThreatLevels().subscribe(item => {
            this.threatLevels = item;
        },
            error => {
                console.error('Error fetching threat levels:', error);
            })
    }

    getIntentions() {
        this.adminService.getIntentions().subscribe(item => {
            this.intentions = item;
        },
            error => {
                console.error('Error fetching intentions:', error);
            })
    }
}


