import { AppComponent } from '../app/app.component';
import { InOperationComponent } from './shared/components/bp/bp-in-operation/bp-in-operation.component';
import { BPService } from './shared/services/bp.service'
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { AppRoutingModule } from './routing/app-routing.module'
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MainLayoutComponent } from './layouts/main/main-layout.component';
import { DetailsLayoutComponent } from './shared/components/bp/details/bp-details-layout.component';
import { EntryPageComponent } from './shared/components/bp/details/detail-items/entry-page-processes.component';
import { DialogAllModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import a from './auth-config.json';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { MaterialModule } from './mat-module';
import { RiskAnylysisComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/risk-analysis.component';
import { SecurityOfProcessingComponent } from './shared/components/bp/details/detail-items/security-of-processing/security-of-processing.component';
import { AssetsComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/assets.component';
import { EntrySecurityOfProcessingComponent } from './shared/components/bp/details/detail-items/security-of-processing/entry-security.component';
import { RiskService } from './shared/services/risk-analysis.service';
import { ApplicationsComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/applications.component';
import { ConsequenceTypesComponent } from './shared/components/bp/details/detail-items/security-of-processing/concequence-types.component';
import { GeneralInfoComponent } from './shared/components/bp/details/detail-items/general-info/general-info.component';
import { GeneralInfoService } from './shared/services/general-info.service';

import { FormsModule } from '@angular/forms';
import { ITAssessmentComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/it-risk-assessment.component';
import { GdprAssessmentComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/gdpr-risk-assessment.component';

import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
import { ITAssessmentHeatmapComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/grids/it-assessment-heatmap.component';
import { GdprAssessmentHeatmapComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/grids/gdpr-assessment-heatmap.component';
import { GdprFullAssessmentComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/gdpr-full-risk.component';
import { ITFullAssessmentComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/it-full-risk.component';
import { GdprAssessmentHeatmapFullComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/grids/full/gdpr-full-assessment-heatmap.component';
import { ITFullAssessmentHeatmapComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-assessment/grids/full/it-full-assessment-heatmap.component';
import { FullThreatComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/full-threat.component';
import { DataProcessorsComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/data-processors.component';
import { DatePipe } from '@angular/common';
import { StandardControlComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/modals/standard-controls.component';
import { StandardControlSpecificationComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/modals/standard-control-specification.component';
import { StandardControlSpecificationCisComponent } from './shared/components/bp/details/detail-items/security-of-processing/risk-analysis/full/modals/standard-control-specification.component-cis';
import { ConsequenceService } from './shared/services/consequence.service';
import { DocMaintenanceService } from './shared/services/documentation-maintenance.serviсe';
import { WheelMaintenanceComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/wheel-maintenance.component';
import { DiscretionaryMaintenanceComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/discretionary-maintenance.component';
import { ArchiveMaintenanceComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/archive-maintenance.component';
import { MaintenanceLogComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/modals/maintenance-log.component';
import { ObservationActionComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/modals/observation-action.component';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ReportPreviewReadonlyComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/modals/report-preview-readonly';
import { ControlReportComponent } from './shared/components/bp/details/detail-items/documentation-maintenance/modals/control-report.component';
import { DropDownListModule, MultiSelectModule, ListBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { AccordionModule, MenuAllModule, SidebarModule, TabModule, TreeViewAllModule } from '@syncfusion/ej2-angular-navigations';
import { ToastAllModule } from '@syncfusion/ej2-angular-notifications';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { HeatMapAllModule } from '@syncfusion/ej2-angular-heatmap';
import { NgModule } from '@angular/core';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ButtonModule, CheckBoxModule, ChipListModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DataTransfersLayoutComponent } from './shared/components/bp/details/detail-items/data-transfers/data-transfers-layout.component';
import { DataTransferService } from './shared/services/data-transfer.service';
import { DataTransfersFormComponent } from './shared/components/bp/details/detail-items/data-transfers/data-transfers-form.component';
import { DataTransfersGridComponent } from './shared/components/bp/details/detail-items/data-transfers/data-transfers-grid';
import { SafeguardsComponent } from './shared/components/bp/details/detail-items/data-transfers/modals/safeguards/safeguards.component';
import { DerogationsComponent } from './shared/components/bp/details/detail-items/data-transfers/modals/derogations/derogations.component';
import { PersonalDataComponent } from './shared/components/bp/details/detail-items/data-transfers/modals/personal-data/personal-data.component';
import { TransferImpactAssessmentComponent } from './shared/components/bp/tia/transfer-impact-assessment.component';
import { TIAComponent } from './shared/components/bp/details/detail-items/data-transfers/tia/tia.component';
import { DocumentEditorAllModule, DocumentEditorContainerAllModule } from '@syncfusion/ej2-angular-documenteditor';
import { TransferCategoriesComponent } from './shared/components/bp/details/detail-items/data-transfers/modals/categories/categories.component';
import { AdministrationComponent } from './shared/components/admin/sections/administration/administration.component';
import { ModelImportComponent } from './shared/components/admin/sections/import/model_import.component';
import { DpoReportComponent } from './shared/components/admin/sections/dpo_reports/dpo_report.component';
import { ModelImportGridComponent } from './shared/components/admin/sections/import/model_import_grid.component';
import { AdministrationService } from './shared/services/admin.service';
import { LegalBasisComponent } from './shared/components/bp/details/detail-items/legal-basis/legal-basis.component';
import { LegalBasisPersonalDataComponent } from './shared/components/bp/details/detail-items/legal-basis/modals/personal-data/personal-data.component';
import { LegalBasisProcessingComponent } from './shared/components/bp/details/detail-items/legal-basis/modals/legal-basis-for-processing/legal-basis-for-processing';
import { LegalBasisService } from './shared/services/legal-basis.service';
import { ActionPlanService } from './shared/services/actionPlanService';
import { ComplianceComponent } from './shared/components/bp/details/detail-items/compliance-gdpr-principles/compliance-with-gdpr-principles.component';
import { ComplianceService } from './shared/services/complianceService';
import { RightsOfDataSubjectsComponent } from './shared/components/bp/details/detail-items/rights-of-data-subjects/rights-of-data-subjects.component';
import { RightsOfDataSubjectsService } from './shared/services/rightsOfDataSubjectsService';
import { SubCategoryFilterPipe } from './shared/pipes/subCategoryFilter';
import { ErasureNegativeComponent } from './shared/components/bp/details/detail-items/rights-of-data-subjects/modals/erasure/erasure-negative.component';
import { RestrictionsComponent } from './shared/components/bp/details/detail-items/rights-of-data-subjects/modals/restrictions/restrictions.component';
import { RestrictionsTotheRightsComponent } from './shared/components/bp/details/detail-items/rights-of-data-subjects/modals/restrictions_to_the_rights/restrictions_to_the_rights.component';
import { DecisionMakingImportanceComponent } from './shared/components/bp/details/detail-items/legal-basis/modals/decision-making-importance/decision-making-importance.component';
import { DecisionMakingSpecialCategoriesComponent } from './shared/components/bp/details/detail-items/legal-basis/modals/decision-making-special-category/decision-making-special-categories.component';
import { CallbackPipe } from './shared/pipes/callback';
import { DecisionMakingPipe } from './shared/pipes/decisionMakingFilter';
import { RightsDecisionMakingFilter } from './shared/pipes/rightsDecisionMakingFilter';
import { CoveredByDecisionMakingFilterPipe } from './shared/pipes/coveredByDecisionMakingFilter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AccessDeniedComponent } from './shared/components/errors/access-denied.component';
import { ManagePermissionsLayoutComponent } from './shared/components/admin/sections/manage-permissions/manage-permissions-layout.component';
import { AuthService } from './shared/services/auth.service';
import { GlobalHttpInterceptorService } from './GlobalHttpInterceptorService';
import { PermissionsPipe } from './shared/pipes/permissionsNonIncludedItemsFilter';
import { DashboardComponent } from './shared/components/dashboard/dashboard.component';
import { DashboardService } from './shared/services/dashboard.service';
import { HeatmapComponent } from './shared/components/dashboard/heatmap.component';
import { ActionPlanComponent } from './shared/components/dashboard/actionPlan';
import { BasicInfoComponent } from './shared/components/direct-menu-pages/basic-info.component';
import { OrganizationService } from './shared/services/organization.service';
import { BusinessFunctionsComponent } from './shared/components/direct-menu-pages/business-functions/business-functions.component';
import { IsRuleBookComponent } from './shared/components/direct-menu-pages/is-rule-book/rule-book.component';
import { RuleBookService } from './shared/services/rule-book.service';
import { LicensedActivityComponent } from './shared/components/direct-menu-pages/modals/licensed-activities.component';
import { FunctionLicensedActivityComponent } from './shared/components/direct-menu-pages/business-functions/modals/function-licensed-activities.component';
import { AdminRuleBookComponent } from './shared/components/admin/data-management/admin-rule-book/admin-rule-book.component';
import { MyRuleBookComponent } from './shared/components/direct-menu-pages/my-rule-book/my-rule-book.component';
import { EstablishBaselineComponent } from './shared/components/direct-menu-pages/my-rule-book/sub-pages/establish-baseline.component';
import { AdminFunctionsComponent } from './shared/components/admin/data-management/admin-functions/admin-functions.component';
import { NumericTextBoxAllModule, SliderModule } from '@syncfusion/ej2-angular-inputs';
import { NewWheelMaintenanceComponent } from './shared/components/general/documentation-maintenance/wheel-maintenance.component';
import { NewObservationActionComponent } from './shared/components/general/documentation-maintenance/modals/observation-action.component';
import { NewMaintenanceLogComponent } from './shared/components/general/documentation-maintenance/modals/maintenance-log.component';
import { InitialBaselineTrackingLogComponent } from './shared/components/general/documentation-maintenance/modals/initial-baseline-tracking-log.component';
import { InitialActionPlanComponent } from './shared/components/header-menu/action-plan/initial-action-plan.component';
import { GanttAllModule } from '@syncfusion/ej2-angular-gantt';
import { GanttChartComponent } from './shared/components/header-menu/gannt-chart/gantt-chart.component';
import { GanttService } from './shared/services/ganntService';
import { ThreatsComponent } from './shared/components/admin/sections/threats/threats.component';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const localUrl = "https://localhost:44394";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: a.credentials.clientId,
      authority: 'https://login.microsoftonline.com/' + a.credentials.tenantId,
      redirectUri: a.configuration.redirectUri,
      postLogoutRedirectUri: a.configuration.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(a.resources.PDHubApi.resourceUri, a.resources.PDHubApi.resourceScopes);
  protectedResourceMap.set(localUrl, a.resources.PDHubApi.resourceScopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect, authRequest: {
      scopes: ['user.read', 'openid', 'profile'],

    }
  };
}

@NgModule({
  declarations:
    [
      AppComponent, InOperationComponent, MainLayoutComponent, DetailsLayoutComponent,
      EntryPageComponent, SecurityOfProcessingComponent, RiskAnylysisComponent, AssetsComponent,
      EntrySecurityOfProcessingComponent, ApplicationsComponent, ConsequenceTypesComponent, AccessDeniedComponent,
      GeneralInfoComponent, ITAssessmentComponent, GdprAssessmentComponent, ManagePermissionsLayoutComponent,
      ITAssessmentHeatmapComponent, GdprAssessmentHeatmapComponent, GdprFullAssessmentComponent,
      ITFullAssessmentComponent, GdprAssessmentHeatmapFullComponent, ITFullAssessmentHeatmapComponent, FullThreatComponent,
      DataProcessorsComponent, StandardControlComponent, StandardControlSpecificationComponent, StandardControlSpecificationCisComponent,
      WheelMaintenanceComponent, DiscretionaryMaintenanceComponent, ArchiveMaintenanceComponent,
      MaintenanceLogComponent, ObservationActionComponent, ReportPreviewReadonlyComponent,
      ControlReportComponent, DataTransfersLayoutComponent, DataTransfersFormComponent, DataTransfersGridComponent,
      SafeguardsComponent, DerogationsComponent, PersonalDataComponent, TransferImpactAssessmentComponent, TIAComponent,
      TransferCategoriesComponent, AdministrationComponent, ModelImportComponent, DpoReportComponent, ControlReportComponent,
      ModelImportGridComponent, LegalBasisComponent, LegalBasisPersonalDataComponent, LegalBasisProcessingComponent, DecisionMakingPipe,
      ComplianceComponent, RightsOfDataSubjectsComponent, SubCategoryFilterPipe, CallbackPipe, RightsDecisionMakingFilter,
      ErasureNegativeComponent, RestrictionsComponent, RestrictionsTotheRightsComponent, DecisionMakingImportanceComponent, DecisionMakingSpecialCategoriesComponent,
      CoveredByDecisionMakingFilterPipe, PermissionsPipe, DashboardComponent, HeatmapComponent, ActionPlanComponent,
      BasicInfoComponent, BusinessFunctionsComponent, IsRuleBookComponent, LicensedActivityComponent, FunctionLicensedActivityComponent,
      AdminRuleBookComponent, MyRuleBookComponent, EstablishBaselineComponent, AdminFunctionsComponent, NewWheelMaintenanceComponent,
      NewObservationActionComponent, NewMaintenanceLogComponent, InitialBaselineTrackingLogComponent, InitialActionPlanComponent, GanttChartComponent,
      ThreatsComponent
    ],
  imports:
    [
      SidebarModule, BrowserModule, ProgressBarModule, RadioButtonModule, MenuAllModule, DropDownListModule, ButtonModule,
      TreeViewAllModule, TabModule, GridAllModule, HttpClientModule, AppRoutingModule, MsalModule, ListViewAllModule,
      DialogAllModule, ChipListModule, MaterialModule, MultiSelectModule, ToastAllModule, FormsModule, GanttAllModule, CheckBoxModule,
      HeatMapAllModule, ChartAllModule, TooltipModule, SplitterModule, SwitchModule, AccordionModule, SliderModule, NumericTextBoxAllModule,
      DropDownButtonModule, ListBoxAllModule, DatePickerModule, DocumentEditorAllModule, DocumentEditorContainerAllModule, MatProgressSpinnerModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true
    },
    ConsequenceTypesComponent,
    MsalService,
    BPService,
    RiskService,
    ConsequenceService,
    DatePipe,
    GeneralInfoService,
    MsalGuard,
    MsalBroadcastService,
    DocMaintenanceService,
    DataTransferService,
    AdministrationService,
    LegalBasisService,
    ActionPlanService,
    ComplianceService,
    RightsOfDataSubjectsService,
    AuthService,
    DashboardService,
    OrganizationService,
    RuleBookService,
    GanttService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
