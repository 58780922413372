import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { isHidden } from "@syncfusion/ej2/treegrid";
import { ActionPlanService } from "src/app/shared/services/actionPlanService";
import { GanttService } from "src/app/shared/services/ganntService";

@Component({
    selector: 'gantt-chart',
    templateUrl: 'gantt-chart.html',
    styleUrls: ['gantt-chart.css'],
    encapsulation: ViewEncapsulation.None,
})
export class GanttChartComponent implements OnInit {
    constructor(
        private ganttDataService: GanttService,
        private actionPlanService: ActionPlanService,
        private router: Router) { }
    public dimensions: any = [];
    public ddlfields: Object = { text: 'name', value: 'id' };
    public taskSettings: object;
    public labelSettings: object;
    public columns: object[];
    public splitterSettings: object;
    public timelineSettings: Object;
    public selectedDimension: string = '';
    @ViewChild('ganntChartGrid')
    public grid?: GridComponent;
    ganttData: any;
    ngOnInit(): void {
        this.getGanttData();
        this.getDimensions();

        this.taskSettings = {
            id: 'taskId',
            name: 'taskName',
            child: 'subTasks',
            subChild: {
                id: 'taskId',
                name: 'taskName',
                child: 'details',
                progress: 'complianceEffect'
            },
            dependency: 'subRuleDisplayId'
        };

        this.columns = [
            { field: 'taskId', width: 250, visible: false },
            { field: 'taskName', width: 400, headerText: 'Sub-rules' }
        ];
        this.labelSettings = {
            leftLabel: 'taskName',
        };
        this.splitterSettings = {
            position: "30%",
            columnIndex: 3
        };
        this.timelineSettings = {
            timelineViewMode: "Month",
            timelineUnitSize: 400
        };
    }
    getGanttData() {
        this.ganttData = this.ganttDataService.getGanttData();
    }

    getDimensions() {
        this.actionPlanService.getDimensions().subscribe(data => {
            this.dimensions = data;
            this.dimensions.push({ id: 0, name: 'All' })
        })
    }

    showTableView() {
        this.router.navigate([`/initial-action-plan`]);
    }

    filterDimension(event: any): void {
        this.selectedDimension = event.itemData.name;

        // Apply the filter
        this.applyDimensionFilter();
    }

    applyDimensionFilter(): void {
        if (this.selectedDimension === 'All') {
            this.grid.dataSource = this.ganttData;
        } else {
            const filteredData = this.ganttData.map(item => {
                const filteredInitialBaselines = item.subTasks.filter(init =>
                    init.taskName === this.selectedDimension
                );

                return {
                    ...item,
                    subTasks: filteredInitialBaselines
                };
            }).filter(item => item.subTasks.length > 0);

            if (filteredData.length > 0) {
                this.grid.dataSource = filteredData;
            } else {
                this.grid.dataSource = [];
            }
        }

        // Refresh the grid to display the updated data source
        this.grid.refresh();
    }
}
